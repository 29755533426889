.c-card-container {
  perspective: 1000px;
  width: 50px;
  height: 70px;

  margin: 6px 8px;
}

.c-img {
  object-fit: cover;
  object-position: center;

  width: 100%;
  height: 100%;

  border: 1px solid #f7f6f5;
  border-radius: 10px;
}

.c-card {
  width: 100%;
  height: 100%;
  position: relative;
  transform-style: preserve-3d;
  transition: transform 0.6s;

  border-radius: 10px;
}

.c-card.flipped {
  transform: rotateY(180deg);
}

.c-card-face {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
}

.c-front {
  background-color: #fff0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.c-back {
  background-color: #fafafa00;
  transform: rotateY(180deg);
  display: flex;
  align-items: center;
  justify-content: center;
}
