body {
  background-color: #35293d;
}

.c-btn {
  padding: 10px 20px;
  border-radius: 50px;
  background-color: #f7f6f5;
  color: black;
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: none;
  color: #34283c;
  box-shadow: 0 0 10px 0 #ffffff70;
}

.c-card-primary {
  padding: 20px;
  margin: 6px 8px;
  border: 1px solid #f7f6f5;
  border-radius: 10px;
  width: 50px;
  height: 70px;
}

.c-card-danger {
  padding: 20px;
  margin: 6px 8px;
  box-shadow: 0 0 10px 0 #e7171770;
  border-radius: 10px;
  width: 50px;
  height: 70px;
}

.c-card-success {
  padding: 20px;
  margin: 6px 8px;
  box-shadow: 0 0 10px 0 #cdbfaa;
  border-radius: 10px;
  width: 50px;
  height: 70px;
}

.c-px-10 {
  padding-inline: 10rem;
}

@media (max-width: 768px) {
  .c-px-10 {
    padding-inline: 5rem;
  }
}

.c-text-primary {
  color: #f7f6f5;
}

.spin-500 {
  animation: spin500 500s linear infinite;
}

@keyframes spin500 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.logo {
  box-shadow: 0 0 10px 0 #ffffff70;
}

.c-box-shadow {
  box-shadow: 0 0 10px 0 #ffffff70;
}
