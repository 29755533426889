.game .row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.game .tile {
  width: 3rem;
  height: 3rem;

  margin: 2px;
}
